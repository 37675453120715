import React, { useRef, useState } from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { Layout, SEO, Grid, Box, Text } from 'components';
import { RichText } from 'prismic-reactjs';
import useAnimationFrame from 'utils/raf';
import gsap from 'gsap';
import { linkResolver } from 'utils/linkResolver';

import { colors, mediaQueries } from 'styles/theme';


const CardContainer = styled(Box)`
  transition: opacity 0.25s ease-out;
  z-index: 2;
`;

CardContainer.defaultProps = {
  mb: 5
};

const CardContent = styled(Box)`
  border-radius: 2rem;
  border: 1px solid ${colors.gray};

  @media screen and (max-width: 39.99em) {
    transform: translate(0, 0) !important;
  }
`;

CardContent.defaultProps = {
  textAlign: 'center',
  py: 5,
  px: [3, 2, 3]
};

const ServiceCard = styled(CardContent)`
  align-self: center;
`;

ServiceCard.defaultProps = {
  gridColumn: ['1 / -1', 'span 5'],
  py: 5,
  px: 3,
  mb: [3, 0]
};

const GeneralContainer = styled(Grid)`
  a {
    text-decoration: none;
    color: ${colors.blue};

    &:hover {
      text-decoration: underline;
    }
  }
`;

const CoinContainer = styled(Box)`
  align-self: center;
  transition: opacity 0.25s ease-out;
  padding:0px;
  border-radius:100%;
  position:relative;

  //animation: coin-animation 8s linear infinite;


  .coin-svg-text {
    text-anchor:middle;
    font-family: 'Work Sans', sans-serif;
    font-weight:normal;
    font-size:10px;
    stroke:none;
    fill:black;

  }

  .coin-svg-text,
  .coin-svg-spin {
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .coin-svg-spin {
    transform-origin: 50% 50% 0px;
    animation-name: circle-anim;
  }

  .coin-svg-text {
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .coin-svg-text-group {
    mask: url(#coin-svg-mask);
  }

  .text-a { animation-name: text-a-anim}
  .text-b { animation-name: text-b-anim}

  @keyframes circle-anim {
     50% { transform: rotate3d(0, 2, 1, 180deg); }
    100% { transform: rotate3d(0, 2, 1, 360deg); }
  }

  @keyframes text-a-anim {
      0% { fill:black; }
     57% { fill:none; }
    87% { fill:black; }
  }

  @keyframes text-b-anim {
      0% { fill:none; }
     57% { fill:black; }
     87% { fill:none; }
    100% { fill:none; }
  }



  ${mediaQueries.lg} {
     padding:40px;
  }

  ${mediaQueries.sm} {

    position: sticky;
    top: 30%;
  }

  img {
    display: block;
    width: 100%;

    @media screen and (max-width: 39.99em) {
      transform: translate(0, 0) !important;
    }
  }
`;

const ServiceList = Text.withComponent('ul');
const CardTitle = Text.withComponent('h3');
const TextContainer = Text.withComponent('div');

const Card = ({ card, index }) => {
  const el = useRef(null);
  const contentEl = useRef(null);
  const [ready, setReady] = useState(false);

  let speed = 0;

  if (index === 1) {
    speed = -0.15;
  } else if (index === 2) {
    speed = -0.1;
  } else if (index === 3) {
    speed = 0.05;
  }

  useAnimationFrame(() => {
    const y = el.current.getBoundingClientRect().top;

    if (y < window.innerHeight) {
      const diff = window.innerHeight - y;

      gsap.set(contentEl.current, {
        y: -diff * speed
      });
    }

    if (!ready) {
      setReady(true);
    }
  });

  const getCardColumn = index => {
    switch (index) {
      case 0:
        return ['2 / -2', '4 / span 5', null, '5 / span 4'];
      case 1:
        return ['2 / -2', '2 / span 5', null, '2 / span 4'];
      case 2:
        return ['2 / -2', '8 / span 5', null, '8 / span 4'];
      default:
        return ['2 / -2', '5 / span 5', null, '5 / span 4'];
    }
  };

  return (
    <CardContainer
      gridColumn={getCardColumn(index)}
      gridRow={index + 1}
      opacity={ready ? 1 : 0}
      ref={el}
    >
      <CardContent
        ref={contentEl}
        bg={card.primary.background}
        color={card.primary.text_color}
      >
        <CardTitle uppercase="true" pb={3} color={card.primary.title_color}>
          {RichText.asText(card.primary.title)}
        </CardTitle>
        <TextContainer>{RichText.render(card.primary.text)}</TextContainer>
      </CardContent>
    </CardContainer>
  );
};

const About = ({ data }) => {
  const page = data.prismic.allAbouts.edges.slice(0, 1).pop().node;
  const services = data.prismic.allTags.edges;

  const metaData = {
    title: page.meta_title ? RichText.asText(page.meta_title) : null,
    description: page.meta_description
      ? RichText.asText(page.meta_description)
      : null
  };

  if (page.meta_image) {
    metaData.image = page.meta_image.url;
    metaData.meta = [
      {
        property: `og:image:width`,
        content: page.meta_image.dimensions.width
      },
      {
        property: `og:image:height`,
        content: page.meta_image.dimensions.height
      }
    ];
  }

  const productServices = services.filter((service, i) => {
    return service.node.type === 'Product & Service';
  });

  const communicationServices = services.filter((service, i) => {
    return service.node.type === 'Communication';
  });

  return (
    <Layout>
      <SEO {...metaData} />
      <Grid pt={[7, 8]}>
        {page.body.map((card, i) => {
          return <Card card={card} index={i} key={i} />;
        })}

        {/* <CoinContainer
          gridColumn={['2 / -2', '9 / span 3']}
          gridRow={['auto', 1]}
          mb={[5, 0]}
        >

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" vectorEffect="non-scaling-stroke" className="coin-svg">
            <defs>
              <mask id="coin-svg-mask">
                <circle cx="50" cy="50" r="40" fill="white" vectorEffect="non-scaling-stroke" className="coin-svg-spin"></circle>
              </mask>
            </defs>
            <g className="coin-svg-text-group">
              <text x="50" y="53" className="coin-svg-text text-a">Product</text>
              <text x="50" y="53" className="coin-svg-text text-b">Communication</text>
            </g>
            <g className="coin-svg-spin">
              <circle cx="50" cy="50" r="40" strokeWidth="1" fill="none" stroke="black" vectorEffect="non-scaling-stroke" className="coin-svg-circle"></circle>
            </g>
          </svg>

        </CoinContainer> */}
      </Grid>

      <Grid>
        <Box gridColumn={['1 / -1', '2 / -2']} mb={[5, 7]} textAlign="center">
          <Text uppercase="true" mb={3}>
            Services
          </Text>

          <Grid gridTemplateColumns="repeat(10, 1fr)" px={0}>
            <ServiceCard>
              <CardTitle uppercase="true" mb={3}>
                Product
              </CardTitle>
              <ServiceList>
                {productServices.map((service, i) => (
                  <li key={i}>{RichText.asText(service.node.tag)}</li>
                ))}
              </ServiceList>
            </ServiceCard>

            <ServiceCard>
              <CardTitle uppercase="true" mb={3}>
                Communication
              </CardTitle>
              <ServiceList>
                {communicationServices.map((service, i) => (
                  <li key={i}>{RichText.asText(service.node.tag)}</li>
                ))}
              </ServiceList>
            </ServiceCard>
          </Grid>
        </Box>
      </Grid>

      <GeneralContainer>
        <Box gridColumn={['2 / -2', 'span 6']} textAlign="center" py={[5, 7]}>
          <CardTitle>{RichText.asText(page.first_paragraph_title)}</CardTitle>
          <TextContainer>
            {RichText.render(page.first_paragraph_action)}
          </TextContainer>
        </Box>

        <Box gridColumn={['2 / -2', 'span 6']} textAlign="center" py={[5, 7]}>
          <CardTitle>{RichText.asText(page.second_paragraph_title)}</CardTitle>
          <TextContainer>
            {RichText.render(page.second_paragraph_action, linkResolver)}
          </TextContainer>
        </Box>
      </GeneralContainer>
    </Layout>
  );
};

export default About;

export const query = graphql`
  {
    prismic {
      allAbouts {
        edges {
          node {
            first_paragraph_title
            first_paragraph_action
            second_paragraph_title
            second_paragraph_action
            meta_title
            meta_description
            meta_image
            body {
              ... on PRISMIC_AboutBodyCard {
                type
                label
                primary {
                  background
                  text_color
                  text
                  title
                  title_color
                }
              }
            }
          }
        }
      }
      allTags {
        edges {
          node {
            type
            tag
          }
        }
      }
    }
  }
`;
